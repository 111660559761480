<template>
  <div>404</div>
</template>
<script>
import {
  mapState
} from 'vuex';
import pageHeader from '@/components/pageHeader.vue';
import pageAside from '@/components/pageAside.vue';
export default {
  components: {
    pageHeader,
    pageAside
  },
  computed: mapState(['testInfo', 'userInfo']),
  data() {
    return {
      pageheaderTitle: '选择分组',
      list: [],
      type:''
    }
  },
  created() {
    this.type = this.$route.query.type;
    this.getGroupBriefList();
  },
  methods: {
    getGroupBriefList() {
      const that = this;
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
        filterWorksName:that.type == 1 ? '测评区' : '准备区',
      }
      const loading = this.$loading({
          lock: true,
           background: 'rgba(0, 0, 0, 0.3)',
           text: '加载中...',
        });
      that.$api.getGroupBriefList(param).then(res => {
        if (res.success) {
          let result = res.body.list;
          this.noGroupCount = res.body.noGroupCount;
          that.list = result;
        } else {
          that.$message(res.msg)
        }
        loading.close();
      })
    },
    refresh() {
      this.list = [];
      this.getGroupBriefList();
    },
    selectFz(groupNo) {
      this.$router.push({
        path:'/examDetail',
        query:{
          groupNo:groupNo,
          type:this.type
        }
      })
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>
<style >
#app {
  display: flex;
}
.el-header {
  background-color: #2797ff;
  height: 85px !important;
}

.el-aside {
  background-color: #2797ff;
}

.el-main {
  background-color: #0e8bff;
  color: #ffffff;
}

body > .el-container {
  margin-bottom: 40px;
}
.el-container {
  overflow: hidden;
}
.el-page-header__content {
  color: #ffffff;
}
</style>
<style lang="scss" scoped>
.fzList {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 30px 100px;
  position: relative;
  /deep/ .el-card__body {
    padding: 0 !important;
  }
  .refreshBtn {
    position: absolute;
    left: 10px;
    top: 43px;
  }
  .box-card {
    margin-bottom: 20px;
    cursor: pointer;
    margin: 10px;
    width: 200px;
    background: rgba(0, 0, 0, 0.12);
    border: none;
    .changeFzItem {
      position: relative;
      .cFzItemTop {
        height: 40px;
        line-height: 50px;
        color: #fff;
        padding-left: 10px;
        font-size: 15px;
      }

      .cFzItemBottom {
        height: 25px;
        line-height: 25px;
        color: #fff;
        background: rgba(0, 0, 0, 0.08);
        font-size: 12px;
        padding-left: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .gou {
        position: absolute;
        width: 19px;
        height: 15px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAPCAYAAAAGRPQsAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkVFQTlBN0E1NUEzOTExRUI4NEM3RDNBRUM2NEI3Q0ZGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkVFQTlBN0E2NUEzOTExRUI4NEM3RDNBRUM2NEI3Q0ZGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RUVBOUE3QTM1QTM5MTFFQjg0QzdEM0FFQzY0QjdDRkYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RUVBOUE3QTQ1QTM5MTFFQjg0QzdEM0FFQzY0QjdDRkYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7BqNywAAABKElEQVR42mIUuTiPgRLwWi+RQfTSfEcgs4+FgTLACzSoD0ingDiUGOYAxPOBWAGIfwNxMhMZhrABcScQ74Ma9AmIXYHeXUyqy5SBeBUQG0H5b4DYCWjQZRAH2WWMQFwGxFE4DPID4nNIBr1ENgg5zHiBeAEQBwHxPyDmA+IZSJbUAHETksHvgNgFiK8g2wYyTAuI1wGxOlQM5NrpQMwPxNOAeBEQByDp+Q7EXugGwTQ2IxmEDDqA+A6aQX+g/JPYwgFkWCQQb8YRTmJo/Cwg3oUrdkCG/QLiECBeQSAme4B4Nj4FsNgEGRgDxEtxqNsCxBWE0g1y0vgLxLFIsQgDD4A4DipPtGEg8B8aLhOg/J/QIHhPTIrGlgNABhZCDboHxGeJzR4AAQYAMfg9jzp7kesAAAAASUVORK5CYII=)
          no-repeat;
        background-size: cover;
        right: 8px;
        top: 5px;
      }
    }
  }
}
.areaName{
  background-color: rgba(255, 255, 255, .3);
  border-radius: 4px;
  margin-right: 20px;
  padding: 0 15px;
}
</style>